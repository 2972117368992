import React from 'react';
import logo from './config/logo.svg';
import './App.css';

// TODO add in any conditionals based on origin. eg. region blocking etc. link may be different
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          It looks like Safesite is not available in your region.
        </p>
        <a
          className="App-link"
          href="https://help.safesitehq.com/en/articles/6203947-safesite-app-availability-outside-the-us-canada-brazil-and-mexico"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
      </header>
    </div>
  );
}

export default App;
