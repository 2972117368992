import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './config/reportWebVitals';
import { datadogLogs } from '@datadog/browser-logs'

// we want to know any numbers on how much this page is getting hit
datadogLogs.init({
  clientToken: 'pub376a3c7f48d637e7cabb15345e0b15e5', // https://app.datadoghq.com/organization-settings/client-tokens
  site: 'ss_unavailable',
  forwardErrorsToLogs: true,
  sampleRate: 100,
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

datadogLogs.logger.info('safesite_unavailable_page_shown') // https://docs.datadoghq.com/logs/log_collection/javascript/#npm-2

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
